import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { lista } from './FazGet.js'

const root = ReactDOM.createRoot(document.getElementById('root'))
const eventos = lista('http://localhost:5000/rotasEvento/')

root.render(
    <App eventos={eventos} />
)